(function ($) {
    'use strict';

    // Initalise Select2
    $('select').select2({
        allowClear: function () {
            return ! $(this).prop('multiple');
        },
        placeholder: function () {
            return $(this).data('placeholder');
        }
    });

    // Initialise TinyMCE
    tinymce.init({
        selector: '.form-editor',
        menubar: false,
        statusbar: false
    });

})(jQuery);
